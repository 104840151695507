import { catchError } from "@/store/Util";
import {
	FilterProperty,
	FiltersEntity,
	ReportData,
	ReportDataEntity,
	ReportFilterSP,
	ReportFiltersSP,
	ResourceDataEntity,
} from "@/interfaces/report";
import {
	ScheduleCreate,
	ScheduleUpdate,
} from "@/interfaces/reports/v2/scheduler";
import { Notification } from "@/interfaces/proccess";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";
import { ReportResourceDataEntity } from "@/models/Reports/v2/Report";
import {
	getDataFromFilters,
	getDimensionMetricParsed,
	getFilters,
} from "@/models/Reports/v2/utils";
import notificationService from "@/services/notification-service";
import ReportService from "@/services/report-service";
import ReportV2Service from "@/services/reports/v2/schedule-service";
import { isCustom } from "@/utils/reportData";
import {
	DEFAULT_MOMENT_SQL_DATE_FORMAT,
	parseDateToString,
} from "@/services/date-service";

export const ReportV2Module = {
	namespaced: true,
	state: () => ({
		schedule: new ScheduleEntity(),
		report: new ReportDataEntity(),
		resources: new ResourceDataEntity(),
		resource_data: new ReportResourceDataEntity(),
		// table_report: new TableReportEntity(),
	}),
	mutations: {
		SET_LOADING_METRIC_DIMENSION(
			state: {
				resource_data: ReportResourceDataEntity;
			},
			loading: boolean = false
		) {
			state.resource_data.loading = {
				...state.resource_data.loading,
				dimensions: loading,
				metrics: loading,
			};
		},
		SET_LOADING_FILTERS(
			state: {
				resource_data: ReportResourceDataEntity;
			},
			loading: boolean = false
		) {
			state.resource_data.loading = {
				...state.resource_data.loading,
				account: loading,
				advertisers: loading,
				campaigns: loading,
				creatives: loading,
				line_items: loading,
				deal_id: loading,
				layer_name: loading,
			};
		},
		SET_STATE(
			state: { [x: string]: any },
			params: { key: string; value: any }
		) {
			state[params.key] = params.value;
		},
		RESET_REPORT(state: {
			report: ReportDataEntity;
			schedule: ScheduleEntity;
			resources: ResourceDataEntity;
		}) {
			state.report = new ReportDataEntity();
			state.schedule = new ScheduleEntity();
			state.resources = new ResourceDataEntity();
		},
		SET_RESOURCES(
			state: { resources: ResourceDataEntity },
			payload: {
				data: {
					account: FilterProperty[];
					advertisers: FilterProperty[];
					campaigns: FilterProperty[];
					creatives: FilterProperty[];
					line_items: FilterProperty[];
					deal_id?: FilterProperty[];
					layer_name?: FilterProperty[];
				};
				isMasterAccount: boolean;
			}
		) {
			if (payload.data.account?.length) {
				state.resources.account = payload.data.account;
			}
			state.resources.advertisers = payload.data.advertisers;
			state.resources.campaigns = payload.data.campaigns;
			state.resources.creatives = payload.data.creatives;
			state.resources.creatives = payload.data.creatives;
			state.resources.line_items = payload.data.line_items;
			state.resources.deal_id = payload.data.deal_id || [];
			state.resources.layer_name = payload.data.layer_name || [];
		},
		SET_FILTERS(
			state: { schedule: ScheduleEntity },
			filters: FiltersEntity
		) {
			state.schedule.form.prepareFilters(filters);
		},
		SET_JOB_ID(
			state: { schedule: ScheduleEntity },
			report_job_id?: number
		) {
			state.schedule.form.setJobId(report_job_id);
		},
		async SET_SCHEDULER_EDIT(
			state: {
				schedule: ScheduleEntity;
				report: ReportDataEntity;
				resources: ResourceDataEntity;
			},
			params: { schedule: any; report: Partial<ReportData> }
		) {
			/**
			 * Set schedule data
			 */
			await state.schedule.form.setSchedule(params.schedule);

			/**
			 * Set report data
			 */
			const reportData = {
				report_type: params.report.report_type,
				start_date: params.report.start_date,
				end_date: params.report.end_date,
				type: params.report.type,
				data_range: params.report.data_range,
				dimensions: params.report.dimensions,
				metrics: params.report.metrics,
				filters: getFilters(
					params.schedule.filters,
					state.resources
				),
			};

			await state.report.setReportData(reportData);
		},
	},
	getters: {
		getState: (state: { [x: string]: any }) => (key: string) => {
			return state[key];
		},
		storeSchedule(state: {
			schedule: ScheduleEntity;
			report: ReportDataEntity;
		}) {
			state.schedule.form = state.schedule.form.mergeToReport(
				state.report
			);
			return state.schedule;
		},
		storeReport(state: {
			schedule: ScheduleEntity;
			report: ReportDataEntity;
		}) {
			return state.report;
		},
		storeResources(state: { resources: ResourceDataEntity }) {
			return state.resources;
		},
		storeResourceData(state: { resource_data: ReportResourceDataEntity }) {
			return state.resource_data;
		},
		// storeTableReport(state: { table_report: TableReportEntity }) {
		// 	return state.table_report;
		// },
	},
	actions: {
		setState<T>({ commit }, params: { key: string; value: T }) {
			return new Promise((resolve) => {
				commit("SET_STATE", params);
				resolve(params);
			});
		},
		async setScheduleFilters({ commit }, filters: FiltersEntity) {
			return await commit("SET_FILTERS", filters);
		},
		async resetReport({ commit }) {
			commit("RESET_REPORT");
		},
		async setResources(
			{ commit, rootState },
			payload: {
				account: FilterProperty[];
				advertisers: FilterProperty[];
				campaigns: FilterProperty[];
				creatives: FilterProperty[];
				line_items: FilterProperty[];
				deal_id: FilterProperty[];
				layer_name: FilterProperty[];
			}
		) {
			const isMasterAccount = rootState.account.account.master_account;
			return await commit("SET_RESOURCES", {
				data: payload,
				isMasterAccount,
			});
		},
		async setJobId({ commit }, report_job_id?: number) {
			commit("SET_JOB_ID", report_job_id);
		},
		async setScheduleEdit({ commit }, params: any) {
			const scheduleId: number = params.id;

			const reportData: Partial<ReportData> = {
				report_type: params.report_type,
				type: params.file_type,
				data_range: await getDataFromFilters(
					params.filters,
					"data_range"
				),
				start_date: await getDataFromFilters(
					params.filters,
					"start_date"
				),
				end_date: await getDataFromFilters(params.filters, "end_date"),
				dimensions: await getDimensionMetricParsed(params.dimensions),
				metrics: await getDimensionMetricParsed(params.metrics),
			};

			const scheduleData = {
				id: scheduleId,
				report_type: params.report_type,
				type: params.file_type,
				emails: JSON.parse(params.emails),
				email_subject: params.email_subject,
				scheduler_type: params.scheduler_type,
				dimensions: params.dimensions,
				metrics: params.metrics,
				filters: params.filters,
				schedule_start_date: params.start_date,
				schedule_end_date: params.end_date,
				include_account_email: Boolean(params.account_email),
			};

			commit("SET_SCHEDULER_EDIT", {
				schedule: scheduleData,
				report: reportData,
			});
		},
		async scheduleShow({}, id: number) {
			try {
				const response = await ReportV2Service.show(id);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * [updateReport]
		 * Match de datos
		 * @param payload
		 * @returns
		 */
		async matchDataScheduleShow(
			{ dispatch },
			payload: { params: any; scheduleShow: any }
		) {
			try {
				/**
				 * Match de datos del result de edit scheduler
				 */
				let newScheduleShow = payload.scheduleShow;
				const scheduler_type = payload.params.scheduler_type_code;
				const report_type = payload.params.report_type_code;
				newScheduleShow.scheduler_type = scheduler_type;
				newScheduleShow.report_type = report_type;

				/**
				 * Actualizar data de scheduler
				 */
				await dispatch("setScheduleEdit", newScheduleShow);

				return await Promise.resolve(newScheduleShow);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * [updateReport]
		 * Extraer datos formateados para payload de {fetchResourceSP}
		 * @param payload
		 * @returns
		 */
		async setSPResources(
			{ dispatch },
			payload: { params: any; scheduleShow: any }
		) {
			try {
				/**
				 * Preparar el payload para [fetchResourceSP]
				 */
				const payloadRSP: ReportFiltersSP = await dispatch(
					"getExtractPayloadSP",
					payload.scheduleShow
				);

				const resources = await dispatch(
					"report_v2/setFiltersFromResponse",
					{ payloadRSP },
					{
						root: true,
					}
				);

				return await Promise.resolve(resources);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * [updateReport]
		 * Fetch de {fetchResourceSP} y set de resources en el store
		 * @param payload
		 * @returns
		 */
		async setFiltersFromResponse(
			{ commit, dispatch },
			payload: { payloadRSP: ReportFiltersSP }
		) {
			try {
				commit("SET_LOADING_FILTERS", true);

				/**
				 * Preparar el payload para [fetchResourceSP]
				 */
				const response = await dispatch(
					"fetchResourceSP",
					payload.payloadRSP
				);

				commit("SET_LOADING_FILTERS", false);

				const resources = {
					account: response.account,
					advertisers: response.advertiser,
					campaigns: response.campaign,
					line_items: response.line_item,
					creatives: response.creative,
					deal_id: response.deal,
					layer_name: response.layer_name
				};

				await dispatch("setResources", resources);

				return await Promise.resolve(resources);
			} catch (error) {
				commit("SET_LOADING_FILTERS", false);
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * Extraer y preparar el payload para {fetchResourceSP}
		 * @param response
		 * @returns
		 */
		async getExtractPayloadSP({ state }, response: any) {
			const convertedFilters = JSON.parse(response.filters);

			let start_d = "";
			let end_d = "";

			/**
			 * isCustom
			 * Parse [start_date & end_date]
			 */
			if (isCustom(convertedFilters.data_range)) {
				start_d = parseDateToString(
					convertedFilters.start_date,
					DEFAULT_MOMENT_SQL_DATE_FORMAT
				);
				end_d = parseDateToString(
					convertedFilters.end_date,
					DEFAULT_MOMENT_SQL_DATE_FORMAT
				);
			}

			const preparePayloadSP: ReportFilterSP = {
				data_range: convertedFilters.data_range,
				start_date: start_d,
				end_date: end_d,
				report_type: response.report_type,
				year: state.report.report_year,
				filters: {
					advertiser_id: convertedFilters.advertisers,
					line_item_id: convertedFilters.line_items,
					campaign_id: convertedFilters.campaigns,
					creative_id: convertedFilters.creatives,
					account_id: convertedFilters.account,
					deal_id: convertedFilters.deal_id,
					layer_name: convertedFilters.layer_name,
				} as ReportFiltersSP,
			};

			return preparePayloadSP;
		},

		/**
		 * LLamar a {report/getFilterSP} y setear los valores de resources {ResourceDataEntity}
		 * @param params
		 * @returns
		 */
		async fetchResourceSP({}, payload: ReportFilterSP) {
			try {
				const response = await ReportService.getFilterSP(payload);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async createSchedule({}, params: ScheduleCreate) {
			try {
				const response = await ReportV2Service.createSchedule(params);
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async updateSchedule({}, params: ScheduleUpdate) {
			try {
				const response = await ReportV2Service.updateSchedule(
					params,
					params.id
				);
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
	},
};
