import i18n from "@/plugins/i18n";
import { AxiosResponse } from "axios";
import { isString } from "lodash";
import { sleep } from "@/utils/convert";
import {
	EnumReportType,
	EnumStatus,
	GeneratedReport,
	ResponseJob,
} from "@/interfaces/report";
import {
	EnumMessageType,
	GenerateReportType,
	GenerateReportTypeEnum,
} from "@/interfaces/reports/v2/report";
import { MessageTypes } from "@/interfaces/proccess";
import { delayAttempt } from "@/utils/report";
import {
	AxiosGetData,
	AxiosPost,
	ForceDownload,
} from "@/services/axios-service";
import { catchServiceErrors, prepareFileName } from "@/utils/services-global";
import { ResponseReportEntity } from "@/models/Reports/v2/GenerateReport";
import store from "@/store";
import { NO_DATA } from "@/services/report-service";

const ROUTE = require("@/api/routes").REPORT;

class GenerateReportService {
	/**
	 * Generar reporte
	 * @param reportType
	 * @param extension
	 * @param payload
	 * @returns
	 */
	async generateReport(
		reportType: GenerateReportType,
		extension: EnumReportType,
		payload: GeneratedReport
	) {
		try {
			const reportTypeCampaigns = [
				GenerateReportTypeEnum.CAMPAIGN_REPORT_SP,
				GenerateReportTypeEnum.REACH_REPORT_SP,
				GenerateReportTypeEnum.BILLING_REPORT_SP,
				GenerateReportTypeEnum.MAIDS_REPORT_SP,
				GenerateReportTypeEnum.STORE_ATRIBUTTION_REPORT_SP,
			];

			if (reportTypeCampaigns.includes(reportType)) {
				/**
				 * Generate Report CAMPAIGN_REPORT_SP & REACH_REPORT_SP
				 */
				return await this.generateReportCampaign(payload, extension);
			}

			if (reportType === GenerateReportTypeEnum.GEOGRAPHY_REPORT_SP) {
				/**
				 * Generate Report GEOGRAPHY_REPORT_SP
				 */
				return await this.generateReportGeography(payload, extension);
			}

			return Promise.resolve({
				type: EnumReportType.NO_TYPE,
				success: false,
				status: EnumStatus.ERROR,
				message: EnumMessageType.REPORT_TYPE_INVALID,
				errors: [],
				data: {},
			});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	/**
	 * Generar reporte tipo: CAMPAIGN_REPORT_SP
	 */
	async generateReportCampaign(
		payload: GeneratedReport,
		extension: EnumReportType
	) {
		/**
		 * Generar report GEOGRAPHY_REPORT_SP y obtener {report_job_id}
		 */
		const reportJob: ResponseJob = await this.generateReportJob(payload);

		await store.dispatch("report_v2/setJobId", reportJob.report_job_id);

		await store.dispatch("generate_report/setState", {
			key: "responseJob",
			value: reportJob,
		});

		if (reportJob.no_data) {
			return Promise.resolve({
				success: true,
				show: true,
				title: MessageTypes.TITLE_INFO,
				message: EnumMessageType.EMPTY,
				btn_text: MessageTypes.CONTINUE,
				type: MessageTypes.INFO,
				url: "",
			});
		}

		if (!reportJob.report_job_id) {
			return Promise.resolve({
				success: false,
				show: true,
				title: MessageTypes.TITLE_FAILED,
				message: EnumMessageType.ERROR,
				btn_text: MessageTypes.CONTINUE,
				type: MessageTypes.FAILED,
				url: "",
			});
		}

		/**
		 * Obtener reporte por {reportJobId} y {extension}
		 * LOOP Se vuelve a intentar si el estado es: in_progress { PENDING }
		 */
		const responseAttempReport = await this.attemptGetReport(
			reportJob.report_job_id,
			extension
		);

		store.dispatch("generate_report/setState", {
			key: "responseAttempReport",
			value: responseAttempReport,
		});

		if (!responseAttempReport?.hasReportUrl()) {
			return Promise.resolve({
				success: false,
				show: true,
				title: MessageTypes.TRYAGAIN,
				message: EnumMessageType.ERROR,
				btn_text: MessageTypes.CONTINUE,
				type: MessageTypes.ERROR,
				to: "",
			});
		}

		return Promise.resolve({
			success: true,
			show: true,
			title: MessageTypes.TITLE_SUCCESS,
			message: EnumMessageType.REPORT_GENERATED,
			btn_text: MessageTypes.CONTINUE,
			type: MessageTypes.SUCCESS,
			url: "",
		});
	}

	/**
	 * Generar reporte tipo: GEOGRAPHY_REPORT_SP
	 */
	async generateReportGeography(
		payload: GeneratedReport,
		extension: EnumReportType
	) {
		const axiosResponse = await this.generateReportPostData(payload);

		const report: ResponseReportEntity = new ResponseReportEntity(
			axiosResponse
		);

		store.dispatch("generate_report/setState", {
			key: "responseAttempReport",
			value: report,
		});

		if (report.isLimitExceeded()) {
			/**
			 * Se agrega una notificacion cuando el status === {LIMIT_EXCEDED_ROWS}
			 */
			return Promise.resolve({
				success: false,
				show: true,
				title: MessageTypes.TITLE_INFO,
				message: i18n
					.t("report.row_limit_reached", { limit: "30.000" })
					.toString(),
				btn_text: MessageTypes.CONTINUE,
				type: MessageTypes.INFO,
				to: "",
			});
		}

		if (!report.isTextPlain() && !report.isXls()) {
			return Promise.resolve({
				success: false,
				show: true,
				title: MessageTypes.TITLE_INFO,
				message: EnumMessageType.EMPTY,
				btn_text: MessageTypes.CONTINUE,
				type: MessageTypes.INFO,
				to: "",
			});
		}

		await ForceDownload(
			axiosResponse,
			prepareFileName("report", extension)
		);

		return Promise.resolve({
			success: true,
			show: true,
			title: MessageTypes.TITLE_SUCCESS,
			message: EnumMessageType.SUCCESS,
			btn_text: MessageTypes.CONTINUE,
			type: MessageTypes.SUCCESS,
			to: "",
		});
	}

	/**
	 * Generar report GEOGRAPHY_REPORT_SP y obtener {report_job_id}
	 * @param payload
	 * @param extension
	 * @returns
	 */
	async generateReportJob(payload: GeneratedReport): Promise<ResponseJob> {
		const axiosResponse = await this.generateReportPostData(payload);
		const { data } = axiosResponse;
		const { response } = data;
		const isNoData =
			isString(response) && String(response).toLowerCase() === NO_DATA;
		return {
			no_data: isNoData,
			report_job_id: data?.response?.report_job_id,
		};
	}

	/**
	 * Get Report by report_job_id && extension
	 * @param report_job_id
	 * @param extension
	 */
	async getReportByJobId(
		reportJobId: number,
		extension: EnumReportType,
		isArraybuffer?: boolean
	) {
		const url = `${ROUTE.REPORT_ROUTE}/get_report/${reportJobId}/${extension}`;
		return await AxiosGetData(url);
	}

	/**
	 * Generico para consultas POST
	 * @param payload
	 * @returns
	 */
	async generateReportPostData(payload: GeneratedReport) {
		const url = `${matchedRoutes()["report_sp"]}`;

		const axiosResponse: AxiosResponse<any, any> = await AxiosPost(
			url,
			payload,
			false,
			payload.report_type === GenerateReportTypeEnum.GEOGRAPHY_REPORT_SP
		);

		return axiosResponse;
	}

	/**
	 * LOOP
	 * Se vuelve a intentar si el estado es: in_progress { PENDING }
	 * @param result
	 * @returns
	 */
	async attemptGetReport(reportJobId: number, extension: EnumReportType) {
		let willBeRetried = true;

		while (willBeRetried) {
			try {
				const response = await this.getReportByJobId(
					reportJobId,
					extension
				);

				const report: ResponseReportEntity = new ResponseReportEntity(
					response
				);

				willBeRetried = report.isWillBeRetried();

				if (!willBeRetried) return Promise.resolve(report);
				await sleep(delayAttempt);
			} catch (error) {
				willBeRetried = false;
				return Promise.reject({});
			}
		}
	}

	/**
	 * Verificar si el estado es: in_progress { PENDING } para volver a intentar
	 * @param result
	 * @returns
	 */
	async isWillBeRetried(message: EnumStatus) {
		if (!isString(message)) return false;
		return [EnumStatus.PENDING].includes(message);
	}
}

function matchedRoutes() {
	return {
		report_sp: ROUTE.REPORT_SP_ROUTE,
	};
}

export default new GenerateReportService();
