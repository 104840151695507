import { find, head, isEmpty, max } from "lodash";
import { ExportData } from "@/interfaces/export";
import AmchartGlobal from "@/views/Admin/Persons/V10/Dependencies/Amcharts/utils/global";
import store from "@/store";
import { getSpecGraphicSorted } from "@/utils/filter-global";
import {
  FilterAudience,
  FilterAudienceDemo,
  FilterAudienceDevice,
  FilterAudienceDigitalBehaviour,
  FilterAudienceHome,
  FilterAudiencePois,
} from "@/interfaces/audience";
import { PoisTotal } from "@/interfaces/person";
import i18n from "@/plugins/i18n";
import {
  getOrderGraphicExport,
  // getFonts,
  matchedLangByKey,
  makePdfMakeDefinitions,
  makeDocHeaderTitle,
  matchedLangIntl,
  getAccountColorFromStore,
  getHeaderNameFromStoreAttribution,
  getStoreAttributionCampaigns,
  isFilteredGraphics,
} from "@/utils/exports";
import { CellColor, Content } from "@/interfaces/pdfmake";
import { AudienceEntity, AudienceStrategy, PoisCountEntity } from "@/models/persons/v10/Audience";
import domtoimage from "dom-to-image";
import { ElementData } from "@/interfaces/persons/v10/person";
import { V10 } from "@/interfaces/persons/v10/route";
import { convLocaleString } from "@/utils/convert";
import { ComboListOptionsCampaign } from "@/utils/resolveObjectArray";
import { catchServiceErrors } from "@/utils/services-global";
import { AnalyzeAudienceType, StoreAttributionType } from "@/interfaces/persons/v10/types";
const ROUTE: V10 = require("@/api/routes").V10;
import ExportingPdf from "@/views/Admin/Persons/V10/Dependencies/Amcharts/utils/exporting";

const marginDefault: number = 80;
const TOTAL_REACH_MULTIPLIER = 1.15;

class ExportService {
  async exportPdf(dom_id: string) {
    try {
      const dataExports = await AmchartGlobal.getAllExporting();
      /**
       * Se instancia { pdfmake } desde el primer elemento del array
       * Contiene la estructura del pdf a exportar
       */
      const pdfmake = await pdfMake();

      /**
       * Enable custom fonts
       */
      //pdfMake.fonts = getFonts();

      /**
       * Prepara los datos para exportar el pdf
       */
      // const dataExportings = await prepareDataExporting(
      // 	orderedDataExports
      // );

      /**
       * Get color from account store
       */
      const cellColor: CellColor = await getAccountColorFromStore();

      /**
       * Header
       */
      const headerData: Content = await buildDocHeader();

      /**
       * { PdfMakeDefinitions }
       * Estructura inicial del objecto para la generacion del pdf
       */
      let doc = await makePdfMakeDefinitions(cellColor);

      /**
       * Initialize default values
       */
      await doc.setup();

      /**
       * Set pdf header
       */
      doc.setAttribute("header", headerData);

      const hasCampaigns: Boolean = false; // await hasStoreAttributionCampaigns();

      if (hasCampaigns) {
        /**
         * Agregar table de campaigns {store-attribution}
         */

        const { headerName, headers } = await getHeaderNameFromStoreAttribution();

        /**
         * Add header title
         */
        doc.addContent(await makeDocHeaderTitle(headerName));

        const tables: Content = await prepareStoreAttributionTable(headers);

        if (!isEmpty(tables)) {
          doc.addContent(tables);
        }

        /**
         * Add separator
         */
        //doc.addContent(addSeparator());
      }

      const { demo, device, digital_behaviour, home, pois, dataPoisTotals } = await buildDataProviderTotalFilters();

      /**
       * Summary
       */
      const tableSummary = await buildSummaryTable(dataPoisTotals);
      doc.addContent(tableSummary);

      /**
       * Table
       * country
       */

      /**
       * Si los graficos tienen filtros aplicados, mostrar los filtros como tablas en el pdf
       */
      const isFiltered: Boolean = await isFilteredGraphics();

      if (isFiltered) {
        /**
         * Title
         */
        const titleFilter: Content = (await buildTitleFilterTable(getTranslateByBy("filter"))) as Content;
        doc.addContent(titleFilter);

        /**
         * Table demo
         * Filters
         */
        if (await hasFiltersByCategory(demo)) {
          const tableFilters = await buildFiltersTable(getTranslateByBy("demographic", false), demo, cellColor.color);
          doc.addContent(tableFilters);
        }

        /**
         * Table device
         * Filters
         */
        if (await hasFiltersByCategory(device)) {
          const tableFilters = await buildFiltersTable(getTranslateByBy("device", false), device, cellColor.color);
          doc.addContent(tableFilters);
        }

        /**
         * Table digital_behaviour
         * Filters
         */
        if (await hasFiltersByCategory(digital_behaviour)) {
          const tableFilters = await buildFiltersTable(
            getTranslateByBy("online_behaviour", false),
            digital_behaviour,
            cellColor.color,
          );
          doc.addContent(tableFilters);
        }

        /**
         * Table home
         * Filters
         */
        if (await hasFiltersByCategory(home)) {
          const tableFilters = await buildFiltersTable(getTranslateByBy("pois", false), home, cellColor.color);
          doc.addContent(tableFilters);
        }

        /**
         * Table pois
         * Filters
         */
        if (await hasFiltersByCategory(pois)) {
          const tableFilters = await buildFiltersTable(getTranslateByBy("pois", false), pois, cellColor.color);
          doc.addContent(tableFilters);
        }
      }

      /**
       * Add separator
       */
      doc.addContent(addSeparator());

      /**
       * Contiene una promesa con las imagenes exportadas
       */
      // const promiseMapped = prepareExportImages(dataExportings);

      /**
       * Iterar cada result de la promesa para agregar la imagen al documento  {doc} del pdf
       */
      // promiseMapped.then((exportings: any[]) => {
      // 	exportings.map((dataURI, index) => {
      // 		/**
      // 		 * Obtener la informacion correspondiente a la imagen
      // 		 */
      // 		const exportData = uniqueDataExports[index];

      // 		/**
      // 		 * Agragar el nombre
      // 		 */
      // 		doc.addContent({
      // 			text: String(exportData.infoData.name).toUpperCase(),
      // 			style: "header",
      // 		});

      // 		/**
      // 		 * Agregar la imagen
      // 		 */
      // 		doc.addContent({
      // 			alignment: "center",
      // 			width: 400,
      // 			image: dataURI,
      // 			margin: [marginDefault, 0, 0, marginDefault],
      // 		});
      // 	});

      let nodes = document.getElementById(dom_id)?.children as HTMLCollection;
      for (const node of Array.from(nodes)) {
        if (!node.classList.contains("exportable")) continue;
        // @ts-ignore
        if (node.ariaExpanded === "true") {
          await domtoimage
            .toJpeg(node, {
              bgcolor: "#ffffff",
            })
            .then(image => {
              doc.addContent({
                alignment: "center",
                width: doc.pageSize.width,
                image,
                margin: [0, 5, 0, 0],
                background: "#ffffff",
              });
            })
            .catch(console.error);
        }
      }

      /**
       * Se crea y descarga el pdf
       */
      pdfmake?.createPdf(doc, null, pdfmake.fonts)?.download(prepareFileName("exporting"));

      return Promise.resolve({ success: true });
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }
}

/**
 *  Instance of pdfmake
 * @param dataExports
 * @returns  Instance of pdfmake
 */
export async function pdfMake() {
  // Returns pdfmake instance
  return ExportingPdf.exporting.getPdfmake();
}

/**
 * Preparar los datos de {exporting}
 * @param dataExports
 * @returns
 */
export async function prepareDataExporting(dataExports: ExportData[]) {
  var exportings = [] as any[];
  var extractedExportings = dataExports.map((exportData: ExportData) => {
    return exportData.exporting;
  });
  exportings = exportings.concat(extractedExportings);
  return exportings;
}

/**
 * Preparar la exportacion de las imagenes
 * @param dataExportings
 * @returns
 */
export function prepareExportImages(dataExportings: any[]) {
  const mapped: any[] = dataExportings.map((exporting: any) => {
    if (exporting) {
      return exporting?.exportImage("jpg");
    }
  });
  return Promise.all(mapped);
}

/**
 * Preparar el nombre del archivo a exportar
 * @param entity
 * @returns
 */
export function prepareFileName(entity: string) {
  return `${entity}-${new Date().getTime()}`;
}

/**
 * Store Attribution
 * @param headers
 * @returns
 */
export async function prepareStoreAttributionTable(headers: any[]) {
  const campaigns: any[] = await getStoreAttributionCampaigns();

  const tables = await Promise.all(
    campaigns.map(async c => {
      return await buildStoreAttributionTable(c, headers);
    }),
  );

  return tables;
}

export async function prepareStackRow(headers: any[], campaign: any, key: string, colSpan?: number) {
  return {
    stack: [
      {
        text: (await getHeaderNameByKey(headers, key)).toUpperCase(),
        style: "infoRowTitle",
      },
      {
        text: campaign[key],
        style: "infoRowValue",
      },
    ],
    colSpan: colSpan,
  };
}

/**
 *
 * @param rowTitle
 * @param rowValue
 * @param colSpan
 * @returns
 */
export async function prepareFilterStackRow(rowTitle: string, rowValue: string | number, colSpan?: number) {
  return {
    stack: [
      {
        text: rowTitle,
        style: "infoRowTitle",
      },
      {
        text: rowValue,
        style: "infoRowValue",
      },
    ],
    colSpan: colSpan,
    alignement: "center",
  };
}

/**
 * Header Name by key
 * @param headers
 * @param key
 * @returns
 */
export async function getHeaderNameByKey(headers: any[], key: string): Promise<string> {
  return find(headers, h => h.field === key)?.headerName || "Default";
}

/**
 * Table
 * Prepare table for each campaign
 * @param campaing
 * @param headers
 * @returns
 */
export async function buildStoreAttributionTable(campaing: any, headers: any) {
  return [
    {
      style: "tableSummary",
      // layout: {
      // 	fillColor: function () {
      // 		return "#f6f6f6";
      // 	},
      // },
      table: {
        widths: ["25%", "25%", "25%", "25%"],
        body: [
          [
            await prepareStackRow(headers, campaing, "id", 2),
            {},
            await prepareStackRow(headers, campaing, "start_date"),
            await prepareStackRow(headers, campaing, "end_date"),
          ],
          [
            await prepareStackRow(headers, campaing, "value", 3),
            {},
            {},
            await prepareStackRow(headers, campaing, "clicks"),
          ],
          [
            await prepareStackRow(headers, campaing, "impressions", 2),
            {},
            await prepareStackRow(headers, campaing, "ctr", 2),
            {},
          ],
        ],
      },
    },
  ];
}

/**
 * Table
 * Title & Date
 * @returns
 */
export async function buildTitleDateTable(color: string) {
  return [
    {
      style: "tableSummary",
      layout: {
        fillColor: (rowIndex: number) => getTableLayoutConfig(rowIndex, color),
      },
      table: {
        widths: ["100%"],
        heights: [20],
        body: [
          [
            {
              text: "AUDIENCIA",
              fontSize: 12,
              bold: true,
            },
          ],
          [
            {
              text: await getDateIntlLang(),
              fontSize: 10,
              bold: true,
            },
          ],
        ],
      },
    },
  ];
}

const createEmptyArrays = (count: number): {}[] => {
  return new Array(count).fill({});
};

/**
 * Table
 * Summary
 * @param dataPoisTotals
 * @returns
 */
export async function buildSummaryTable(dataPoisTotals: PoisTotal) {
  const campaigns: ComboListOptionsCampaign[] = store.getters["person/getCampaigns"];
  const strategy = store.getters["audience/getStrategy"] as AudienceStrategy;
  /** If the campaign is empty, it means that it's just an Audience */
  const hasCampaigns = !isEmpty(campaigns);

  const isPois = strategy.type === AnalyzeAudienceType.POIS;

  let table = [
    {
      style: "tableSummary",
      layout: "noBorders",
      table: {
        widths: ["8%", "8%", "9%", "8%", "8%", "9%", "8%", "8%", "9%", "8%", "8%", "9%"],
        body: [
          [
            await prepareFilterStackRow(
              getTranslateByBy("countUser") + (hasCampaigns ? " (*)" : ""),
              convLocaleString(parseInt(dataPoisTotals.total.toString())),
              3,
            ),
            ...createEmptyArrays(2),
            isPois
              ? await prepareFilterStackRow(getTranslateByBy("radio"), dataPoisTotals.radio.toString(), 3)
              : await prepareFilterStackRow("", "", 3),
            ...createEmptyArrays(2),
            await prepareFilterStackRow(getTranslateByBy("country"), (await getCountryNameFilter()) || "", 6),
            ...createEmptyArrays(5),
          ],
        ],
      },
    },
  ];
  if (dataPoisTotals.totalPois) {
    table[0].table.body.push([
      await prepareFilterStackRow(
        getTranslateByBy("totals"),
        convLocaleString(parseInt(dataPoisTotals.totalPois.toString())),
        3,
      ),
      ...createEmptyArrays(2),
      await prepareFilterStackRow(
        getTranslateByBy("totalPrivate"),
        convLocaleString(parseInt(dataPoisTotals.totalPrivatePois.toString())),
        3,
      ),
      ...createEmptyArrays(2),
      await prepareFilterStackRow(
        getTranslateByBy("totalPublic"),
        convLocaleString(parseInt(dataPoisTotals.totalPublicPois.toString())),
        3,
      ),
      ...createEmptyArrays(2),
      await prepareFilterStackRow(
        getTranslateByBy("totalOOH"),
        convLocaleString(parseInt(dataPoisTotals.totalOOH.toString())),
        3,
      ),
      ...createEmptyArrays(2),
    ]);
  }
  if (hasCampaigns) {
    table[0].table.body.push(new Array(12).fill({}));
    table[0].table.body.push([
      {
        alignement: "left",
        colSpan: 12,
        stack: [
          {
            style: "helpField",
            text: getTranslateByBy("countUserRequired", false),
          },
        ],
      },
      ...new Array(11).fill({}),
    ]);
  }

  return table;
}

/**
 * Table
 * Country
 * @returns
 */
export async function buildCountryTable() {
  if (!getCountryNameFilter) return;
  return [
    {
      style: "tableSummary",
      layout: "noBorders",
      table: {
        widths: ["100%"],
        heights: [15],
        body: [[await prepareFilterStackRow(getTranslateByBy("country"), (await getCountryNameFilter()) || "")]],
      },
    },
  ];
}

export async function buildTitleFilterTable(text: string = "", alignment: string = "left") {
  return [
    {
      alignment: alignment,
      text: text,
      style: "title",
    },
  ];
}

export async function buildTitleFilterColumnTable(text: string = "") {
  return [
    {
      alignment: "justify",
      style: "title",
      columns: [
        {
          text: text,
          fontSize: 12,
          alignment: "lef",
          bold: true,
          width: "*",
        },
        {
          text: await getDateIntlLang(),
          fontSize: 10,
          alignment: "right",
          width: "*",
        },
      ],
    },
  ];
}

/**
 * Table
 * Filters by category
 * @param categoryFilter
 * @param data
 * @returns
 */
export async function buildFiltersTable(
  categoryFilter: string,
  data:
    | FilterAudienceDemo
    | FilterAudienceDevice
    | FilterAudienceDigitalBehaviour
    | FilterAudienceHome
    | FilterAudiencePois,
  color: string = "#000000",
) {
  let tableData = {
    widths: ["*", "*"],
    style: "tableFilter",
    body: [
      [
        {
          style: "colorTableCell",
          text: categoryFilter,
          colSpan: 2,
          color: color,
        },
        {},
      ],
    ],
  };

  for (const [key, value] of Object.entries(data)) {
    const filters: string[] = value || [];
    if (!isEmpty(filters)) {
      const row: any[] = [await prepareFilterStackRow(key, filters?.join(", "), 2), {}];
      tableData.body.push(row);
    }
  }

  return [
    {
      style: "tableSummary",
      layout: {
        fillColor: (rowIndex: number) => getTableLayoutConfig(rowIndex, color),
      },
      table: tableData,
    },
  ];
}

/**
 * Get formatted date with {storeLanguage}
 * @returns
 */
export async function getDateIntlLang() {
  const storeLanguage: string = store.getters["internationalization/getLanguage"];

  return new Date().toLocaleDateString(matchedLangIntl[storeLanguage], {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

/**
 * Check if has some filters by category
 * @param category
 * @returns
 */
export async function hasFiltersByCategory(
  category:
    | FilterAudienceDemo
    | FilterAudienceDevice
    | FilterAudienceDigitalBehaviour
    | FilterAudienceHome
    | FilterAudiencePois,
) {
  return Object.values(category).some(x => !isEmpty(x));
}

export function getTableLayoutConfig(rowIndex: number, color: string) {
  return rowIndex === 0 ? color : null;
}

/**
 * Add separator
 * @param marginBoton
 * @returns
 */
export function addSeparator(marginBoton: number = 20) {
  return {
    margin: [0, 0, 0, marginBoton],
    text: " ",
  } as Content;
}

export function buildHeader(marginBoton: number = 20) {
  return {
    margin: [0, 0, 0, marginBoton],
    text: " ",
  } as Content;
}

/**
 * Get translated text by key
 * @param key
 * @returns
 */
export function getTranslateByBy(key: string, upper: Boolean = true) {
  const langValue: string = matchedLangByKey[key];
  let translated = i18n.t(langValue)?.toString();
  return upper ? translated.toUpperCase() : translated;
}

/**
 * Data
 * Totals pois & audience filters
 * @returns
 */
export async function buildDataProviderTotalFilters(): Promise<{
  demo: FilterAudienceDemo;
  device: FilterAudienceDevice;
  digital_behaviour: FilterAudienceDigitalBehaviour;
  home: FilterAudienceHome;
  pois: FilterAudiencePois;
  dataPoisTotals: PoisTotal;
}> {
  // PERSONAS_V4
  // const audienceFilters: FilterAudience =
  // 	store.getters["audience_graphics/getAudienceFilters"];

  // const distinctUser: number = store.getters["audience/getDistinctUser"];

  // const poisReach: PoisReach = store.getters["pois_graphics/getPoisReach"];

  // const dataPoisTotals: PoisTotal = {
  // 	distinctUser,
  // 	radio: audienceFilters.radio,
  // 	totalPois: poisReach.total,
  // 	totalPrivatePois: poisReach.private,
  // 	totalPublicPois: poisReach.public,
  // };

  // const { demo, device, digital_behaviour, home, pois } = audienceFilters;

  // return { demo, device, digital_behaviour, home, pois, dataPoisTotals };

  const audienceFilters: FilterAudience = store.getters["audience_graphics/getAudienceFilters"];

  const audience: AudienceEntity = store.getters["audience/getAudience"];
  const poisCount: PoisCountEntity = store.getters["audience/getPoisCount"];
  const strategy = store.getters["audience/getStrategy"] as AudienceStrategy;

  if (strategy.type === AnalyzeAudienceType.POIS && !poisCount.isFetched()) {
    await store.dispatch("audience/fetchPois", {
      breakdown: true,
    });
  }

  const totalFilteredReach = audience.total_reach.source.filter(
    reach => reach.name === StoreAttributionType.COOKIE || reach.name === StoreAttributionType.MAID,
  );

  let dataPoisTotals: PoisTotal = {
    total: (max(totalFilteredReach.map(reach => reach.uniques)) || 0) * TOTAL_REACH_MULTIPLIER,
    radio: audience.last_filters.poi_distance[0]?.name || audience.proximity_to_poi.source.slice(-1)[0]?.name || 0,
    totalPois: poisCount.getTotal(),
    totalPrivatePois: poisCount.getPrivate(),
    totalPublicPois: poisCount.getPublic(),
    totalOOH: poisCount.getOoh(),
  };

  const { demo, device, digital_behaviour, home, pois } = audienceFilters;
  return { demo, device, digital_behaviour, home, pois, dataPoisTotals };
}

/**
 * Filter
 * Get country name
 * @returns
 */
export async function getCountryNameFilter() {
  const countryName: ElementData = store.getters["person/getCountry"];
  return countryName?.value || null;
}

function convertToDataURL(path) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = path;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;

      let context = canvas.getContext("2d");
      if (context) {
        context.drawImage(image, 0, 0);
      }

      resolve(canvas.toDataURL());
    };
    image.onerror = reject;
  });
}

export async function buildDocHeader() {
  let image = await convertToDataURL(require("@/assets/logos/personas_logo.png"));
  /**
   * Add verification if there's campaigns store on LocalStorage
   * meaning that is a Store Attribution audience.
   */
  const campaigns: ComboListOptionsCampaign[] = store.getters["person/getCampaigns"];
  /** If the campaign is empty, it means that it's just an Audience */
  const hasCampaigns = !isEmpty(campaigns);

  return {
    table: {
      widths: [10, 100, "*", "55%", 10],
      heights: [20],
      body: [
        [
          {},
          {
            image,
            rowSpan: 2,
            alignment: "left",
            fit: [140, 200],
            marginTop: 5,
          },
          {},
          {
            text: getTranslateByBy(hasCampaigns ? "store_attribution" : "audience"),
            fontSize: 18,
            bold: true,
            alignment: "right",
            characterSpacing: 2,
            margin: [0, 10, 0, 0],
            with: "80%",
          },
          {},
        ],
        [
          {},
          {},
          {},
          {
            text: await getDateIntlLang(),
            fontSize: 8,
            bold: false,
            italics: true,
            alignment: "right",
            characterSpacing: 1,
            margin: [0, 0, 0, 10],
            with: "80%",
            height: 40,
          },
          {},
        ],
      ],
    },
    style: ["tableSummary", "colorTableCell"],
    margin: [0, 0, 0, 20],
    height: 40,
    layout: "noBorders",
  } as Content;
}

export default new ExportService();
