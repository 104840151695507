import { ReportRun, GeneratedReport, ReportFilterSP, EnumReportType } from "@/interfaces/report";
//@ts-ignore
import { EXCLUDE_CREATIVES, EXCLUDE_LINE_ITEM } from "@/views/Admin/Reports/Form/ReportForm.vue";
import moment from "moment-timezone";
import { isEmpty } from "lodash";

moment.locale("es");

// Configs Date
export const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_DATE_TIME_FORMAT_FILTER = "YYYY-MM-DD";

export const isCustom = (data: string) => data === "custom";

/**
 * Initialize Report Data
 * @returns
 */
export function ReportingForm() {
	return {
		report_type: "",
		start_date: undefined,
		end_date: undefined,
		data_range: "",
		type: "",
		dimension: [] as Array<any>,
		metric: [] as Array<any>,
		filters: {
			advertisers: [] as Array<any>,
			campaigns: [] as Array<any>,
			line_items: [] as Array<any>,
			creatives: [] as Array<any>,
			account: [] as Array<any>,
			deal_id: [] as Array<any>,
			layer_name: [] as Array<any>
		},
	};
}

/**
 * Initialize Report Data
 * @returns
 */
export function ResourcesReportingForm() {
	return {
		report_type: [] as Array<any>,
		data_range: [] as Array<any>,
		type: [] as Array<any>,
		dimension: [] as Array<any>,
		metric: [] as Array<any>,
		filteredDimensions: [] as Array<any>,
		filteredMetrics: [] as Array<any>,
		account: [] as Array<any>,
		advertisers: [] as Array<any>,
		campaigns: [] as Array<any>,
		line_items: [] as Array<any>,
		creatives: [] as Array<any>,
		deal_id: [] as Array<any>,
		layer_name: [] as Array<any>,
		report_dimensions: {},
		report_metrics: [] as Array<any>,
		report_all_type: [] as Array<any>,
		scheduler_type: [] as Array<any>
	};
}

export async function prepareReportParams(params: {
	filters: {
		account: Array<any>;
		advertisers: Array<any>;
		line_items: Array<any>;
		campaigns: Array<any>;
		creatives: Array<any>;
		deal_id: Array<any>;
		layer_name: Array<any>;
	};
	report_type: string;
	data_range: string;
	start_date: Date;
	end_date: Date;
	type: string;
	dimension: Array<any>;
	metric: Array<any>;
}) {
	const advertisers: Array<any> = params.filters.advertisers;
	const line_items: Array<any> = params.filters.line_items;
	const campaigns: Array<any> = params.filters.campaigns;
	const creatives: Array<any> = params.filters.creatives;
	const account: Array<any> = params.filters.account;
	const deal_id: Array<any> = params.filters.deal_id;
	const layer_name: Array<any> = params.filters.layer_name;

	const test = process.env.VUE_APP_REPORT_TEST == "true";

	let start_d = "";
	let end_d= "";

	if(isCustom(params.data_range) && params.start_date){
		start_d = moment(params.start_date).format(DEFAULT_DATE_TIME_FORMAT);
	}

	if(isCustom(params.data_range) && params.end_date){
		end_d = moment(params.end_date).format(DEFAULT_DATE_TIME_FORMAT);
	}

	return {
		test: test,
		report_name: params.report_type,
		data_range: params.data_range,
		start_date: start_d,
		end_date: end_d,
		/*
		start_date: isCustom(params.data_range)
			? moment(params.start_date).format(DEFAULT_DATE_TIME_FORMAT)
			: undefined,
		end_date: isCustom(params.data_range)
			? moment(params.end_date).format(DEFAULT_DATE_TIME_FORMAT)
			: undefined,
		*/
		type: params.type,
		dimensions: params.dimension.map((a: { id: string }) => a.id),
		metrics: params.metric.map((a: { id: string }) => a.id),
		filters: {
			advertiser_id: advertisers
				.map((m: { id: any }) => String(m.id))
				.toString(),
			line_item_id: !EXCLUDE_LINE_ITEM.includes(params.report_type) ? line_items
				.map((m: { id: any }) => String(m.id))
				.toString() : undefined,
			campaign_id: campaigns
				.map((m: { id: any }) => String(m.id))
				.toString(),
			creative_id: !EXCLUDE_CREATIVES.includes(params.report_type) ? creatives
				.map((m: { id: any }) => String(m.id))
				.toString() : undefined,
			account_id: account
				.map((m: { id: any }) => String(m.id))
				.toString()
		},
		report_type: params.report_type,
	} as ReportRun;
}

export function preparedFiltersEntitys(){
	return {
		account:{
			type: "account",
			key: "external_id",
			resourceName: "account",
			requireFilter: false,
			filter: null
		},
		advertisers:{
			type: "advertiser",
			key: "external_id",
			resourceName: "advertisers",
			requireFilter: false,
			filter: null,
			dependency: null,
			delete: ["campaigns", "line_items", "creatives"]
		},
		line_items:{
			type: "line_item",
			key: "external_id",
			resourceName: "line_items",
			requireFilter: true,
			filter: "campaigns",
			dependency: "campaigns",
			delete: ["creatives"]
		},
		campaigns:{
			type: "campaign",
			key: "external_id",
			resourceName: "campaigns",
			requireFilter: true,
			filter: "advertisers",
			dependency: "advertisers",
			delete: ["line_items", "creatives"]
		},
		creatives:{
			type: "creative",
			key: "external_id",
			resourceName: "creatives",
			requireFilter: true,
			filter: "line_items",
			dependency: "line_items",
			delete: []
		},
		deal_id:{
			type: "deal_id",
			key: "key",
			resourceName: "deal_id",
			requireFilter: false,
			filter: "deal_id",
			dependency: "deal_id",
			delete: []
		},
		layer_name:{
			type: "layer_name",
			key: "key",
			resourceName: "layer_name",
			requireFilter: false,
			filter: "layer_name",
			dependency: "layer_name",
			delete: []
		}
	}
}

/**
 * Report v2
 * Parepara el payload con los filtros seleccionados para generar el reporte
 * @param params 
 * @returns 
 */
export async function prepareReportToSP(params: {
	filters: Record<string, Array<any>>;
	report_type: string;
	report_year: number;
	data_range: string;
	start_date: Date;
	end_date: Date;
	type: EnumReportType;
	dimensions: object;
	metrics: object;
}) {
	const advertisers: Array<any> = params.filters.advertisers;
	const line_items: Array<any> = params.filters.line_items;
	const campaigns: Array<any> = params.filters.campaigns;
	const creatives: Array<any> = params.filters.creatives;
	const account: Array<any> = params.filters.account;
	const deal_id: Array<any> = params.filters.deal_id;
	const layer_name: Array<any> = params.filters.layer_name;

	let start_d = "";
	let end_d= "";

	if(isCustom(params.data_range) && params.start_date){
		start_d = moment(params.start_date).format(DEFAULT_DATE_TIME_FORMAT_FILTER);
	}

	if(isCustom(params.data_range) && params.end_date){
		end_d = moment(params.end_date).format(DEFAULT_DATE_TIME_FORMAT_FILTER);
	}

	const dims_metrics = Object.assign(params.dimensions, params.metrics);
	const dims_metrics_send = Object.fromEntries(Object.entries(dims_metrics).filter(([key, value]) => value == 1));

	return {
		data_range: params.data_range,
		start_date: start_d,
		end_date: end_d,
		type: params.type,
		metrics: dims_metrics_send,
		filters: {
			advertiser_id: advertisers
				.map((m: { key: any }) => Number(m.key)),
			line_item_id: line_items
				.map((m: { key: any }) => Number(m.key)),
			campaign_id: campaigns
				.map((m: { key: any }) => Number(m.key)),
			creative_id: creatives
				.map((m: { key: any }) => Number(m.key)),
			account_id: account
				.map((m: { key: any }) => Number(m.key)),
				deal_id: deal_id
				.map((m: { key: any }) => m.key),
			layer_name: layer_name
				.map((m: { key: any }) => Number(m.key))
		},
		report_type: params.report_type,
		year: params.report_year,
	} as GeneratedReport;
}

/**
 * Reporte v2
 * Prepara el payload con los filtros seleccionados para obtener el listado que alimenta los combox de los filtros
 * @param params 
 * @returns 
 */
export async function prepareFilterToSP(params: {
	filters: {
		account: Array<any>;
		advertisers: Array<any>;
		line_items: Array<any>;
		campaigns: Array<any>;
		creatives: Array<any>;
		deal_id: Array<any>;
		layer_name: Array<any>;
	};
	data_range: string;
	start_date: Date;
	end_date: Date;
	report_type: string;
	report_year: number;
	omit: string;
}) {

	const account: Array<any> = params.omit == "account" ? [] : params.filters.account;
	const advertisers: Array<any> = params.omit == "advertisers" ? [] : params.filters.advertisers;
	const line_items: Array<any> = params.omit == "line_items" ? [] : params.filters.line_items;
	const campaigns: Array<any> = params.omit == "campaigns" ? [] : params.filters.campaigns;
	const creatives: Array<any> = params.omit == "creatives" ? [] : params.filters.creatives;
	const deal_id: Array<any> = params.omit == "deal_id" ? [] : params.filters.deal_id;
	const layer_name: Array<any> = params.omit == "layer_name" ? [] : params.filters.layer_name;

	let start_d = "";
	let end_d= "";

	let report_type = params.report_type;

	if(isCustom(params.data_range) && params.start_date){
		start_d = moment(params.start_date).format(DEFAULT_DATE_TIME_FORMAT_FILTER);
	}

	if(isCustom(params.data_range) && params.end_date){
		end_d = moment(params.end_date).format(DEFAULT_DATE_TIME_FORMAT_FILTER);
	}

	return {
		data_range: params.data_range,
		start_date: start_d,
		end_date: end_d,
		report_type: report_type,
		year: params.report_year,
		filters: {
			advertiser_id: advertisers
				.map((m: { key: any }) => Number(m.key)),
			line_item_id: line_items
				.map((m: { key: any }) => Number(m.key)),
			campaign_id: campaigns
				.map((m: { key: any }) => Number(m.key)),
			creative_id: creatives
				.map((m: { key: any }) => Number(m.key)),
			account_id: account
				.map((m: { key: any }) => Number(m.key)),
				deal_id: deal_id
				.map((m: { key: any }) => m.key),
			layer_name: layer_name
				.map((m: { key: any }) => m.key)
		}
	} as ReportFilterSP;
}
