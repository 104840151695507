import { Paginated, SortingOption } from "@/interfaces/paginated";
import { UserDataCreate, UserFilters } from "@/interfaces/user";
import {
	AxiosGet,
	AxiosPatch,
	AxiosPost,
	GetData,
	GetErrors,
	GetMessage,
} from "@/services/axios-service";
import { catchServiceErrors, downloadFile, modes, prepareParams } from "@/utils/services-global";
import { isUndefined } from "lodash";
import { UserDataUpdate } from "../interfaces/user";

const ROUTE = require("../api/routes").USER;

export const ROLES_ROUTE = "/api/list/roles";
export const POSITIONS_ROUTE = "/api/list/user_positions";
export const CAMPAIGN_OWNERS_ROUTE = "/api/list/campaign_owners";

const ENTITY: string = "user";

class UserService {
	async all(filters?: UserFilters, options?: SortingOption) {
		try {
			let filter = "";

			if (!isUndefined(filters)) {
				filter = getFilters(filters);
			}

			const url = await prepareParams({
				route: ROUTE.USER_ROUTE,
				entity: ENTITY,
				paginated: undefined,
				fields: [],
				mode: modes.ALL,
				filter: filter,
				options: options,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async list(params: { filters?: UserFilters; options?: SortingOption }) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.USER_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async getTraffickers(params: {
		filters?: UserFilters;
		options?: SortingOption;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: CAMPAIGN_OWNERS_ROUTE,
				entity: ENTITY,
				mode: modes.LIST,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async paginated(params: {
		paginated?: Paginated;
		filters?: UserFilters;
		options?: SortingOption;
		fields?: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			const url = await prepareParams({
				route: ROUTE.USER_ROUTE,
				entity: ENTITY,
				mode: modes.PAGINATED,
				filter: filter,
				...params,
			});

			const response = await AxiosGet(url);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async download(params: {
		paginated?: Paginated;
		filters?: UserFilters;
		options?: SortingOption;
		fields: Array<string>;
	}) {
		try {
			let filter = "";

			if (!isUndefined(params.filters)) {
				filter = getFilters(params.filters);
			}

			await downloadFile({
				entity: ENTITY,
				route: ROUTE.USER_ROUTE,
				filter,
				mode: modes.DOWNLOAD,
				...params,
			});

			return Promise.resolve({});
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async listRoles() {
		try {
			const response = await AxiosGet(ROLES_ROUTE);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
	async listPositions() {
		try {
			const response = await AxiosGet(POSITIONS_ROUTE);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async create(user: UserDataCreate) {
		try {
			const response = await AxiosPost(ROUTE.USER_ROUTE, user);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async show(id: number) {
		try {
			const response = await AxiosGet(`${ROUTE.USER_ROUTE}/${id}`);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async update(user: UserDataUpdate, id: number) {
		try {
			const response = await AxiosPost(
				`${ROUTE.USER_ROUTE}/${id}`,
				user
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}

	async setAccount(account_id: number) {
		try {
			const response = await AxiosPatch(
				`${ROUTE.USER_ROUTE}/set_account/${account_id}`,
				{}
			);
			return Promise.resolve(GetData(response));
		} catch (error) {
			return await catchServiceErrors(error);
		}
	}
}

function getFilters(filters: UserFilters): string {
	let filter = "";

	const id = !!filters.id ? filters.id : "";
	const name = !!filters.name ? filters.name : "";
	const last_name = !!filters.last_name ? filters.last_name : "";
	const last_access = !!filters.last_access ? filters.last_access : "";
	const email = !!filters.email ? filters.email : "";
	const role_description = !!filters.role_description
		? filters.role_description
		: "";
	const active =
		typeof filters.active === "undefined" ? "" : !!filters.active ? 1 : 0;

	var symbol = "";

	if (id) {
		filter += `${symbol}filters[users.id]=${id}`;
		symbol = "&";
	}

	if (name) {
		filter += `${symbol}filters[users.name]=${name}`;
		symbol = "&";
	}

	if (last_name) {
		filter += `${symbol}filters[users.last_name]=${last_name}`;
		symbol = "&";
	}

	if (last_access) {
		filter += `${symbol}filters[users.last_access]=${last_access}`;
		symbol = "&";
	}

	if (email) {
		filter += `${symbol}filters[users.email]=${email}`;
		symbol = "&";
	}

	if (role_description) {
		filter += `${symbol}filters[users.description]=${role_description}`;
		symbol = "&";
	}

	if (active) {
		filter += `${symbol}filters[users_roles.active]=${active}`;
		symbol = "&";
	}

	return filter;
}

export default new UserService();
